const isFrozen = Symbol('Frozen');
/**
 * Prevents the object or it's descendants from being modified
 * @param obj The object to be frozen
 */
export function deepFreeze(obj) {
    if (typeof obj !== 'object' || !obj || obj[isFrozen]) {
        return obj;
    }
    // eslint-disable-next-line no-param-reassign
    obj[isFrozen] = true;
    Object.values(obj).forEach(deepFreeze);
    Object.freeze(obj);
    return obj;
}
/**
 * Applies given color to an svg icon string
 * @param svgIcon Svg icon string, with 'fill' set to 'currentColor' in it
 * @param color
 * @returns Changed svg icon string
 */
export function applyColor(svgIcon, color) {
    return svgIcon ? svgIcon.replace(/currentColor/g, color) : '';
}
const includesAny = (str, subs) => {
    return subs.some((v) => str.includes(v));
};
/**
 * Checks if a MediaDeviceInfo device is a front camera, according to it's label
 * (eng and fr supported)
 * @param device
 * @returns True if device is a front camera
 */
export const isFrontCamera = (device) => includesAny(device.label.toLowerCase(), ['front', 'avant']);
/**
 * Checks if a MediaDeviceInfo device is a back camera, according to it's label
 * (eng and fr supported)
 * @param device
 * @returns True if device is a back camera
 */
export const isBackCamera = (device) => includesAny(device.label.toLowerCase(), ['back', 'arrière', 'arriere']);
/**
 * Object providing Dyte video capabilities
 */
export const Video = {
    // true if the current browser is supported by Dyte
    isSupported: true, // TODO add logic here, if needed
};
// Returns true if 'participant' is same as 'speaker' and has mic turned on
export const isSpeaking = (participant, speaker) => participant &&
    participant.audioEnabled &&
    speaker &&
    speaker.id === participant.id;
