import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteAudioVisualizer, DyteNameTag, useLanguage, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import InQStyleGuide from '../../../constants';
import { NetworkQualityVisualizer } from '../../NetworkQualityVisualizer/NetworkQualityVisualizer';
const useStyles = makeStyles(() => ({
    participantsContainer: {
        position: 'relative',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        height: '2rem',
        borderRadius: '0.85rem',
        backgroundColor: InQStyleGuide.green,
        justifyContent: 'space-between',
        padding: '1rem 0.5rem',
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    nameTag: {
        background: 'none',
        color: InQStyleGuide.white,
    },
}));
export function ImmersiveSelfView() {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLanguage();
    return (_jsxs(Box, Object.assign({ className: clsx(classes.participantsContainer) }, { children: [_jsx(Box, Object.assign({ className: clsx(classes.section) }, { children: _jsx(DyteAudioVisualizer, { participant: meeting.self, slot: "start" }) })), _jsx(Box, Object.assign({ className: clsx(classes.section) }, { children: _jsx(DyteNameTag, { participant: meeting.self, meeting: meeting, className: classes.nameTag, t: languagePack }) })), _jsx(Box, Object.assign({ className: clsx(classes.section) }, { children: _jsx(NetworkQualityVisualizer, {}) }))] })));
}
