import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
const screenShareVideoElementId = 'screenShareVideo';
const useStyles = makeStyles(() => ({
    videoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    video: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
    },
}));
// Custom component to show shared content used on the GalleryView,
// as the default DyteScreenshareView component adds additional text and controls under the shared content
export default function SharedContentTile({ participant, }) {
    const classes = useStyles();
    useEffect(() => {
        const videoElement = document.getElementById(screenShareVideoElementId);
        // Stream shared screen
        if (videoElement &&
            participant &&
            participant.screenShareTracks &&
            participant.screenShareTracks.video) {
            const videoStream = new MediaStream();
            videoStream.addTrack(participant.screenShareTracks.video);
            videoElement.srcObject = videoStream;
            videoElement.play().catch((error) => {
                console.error('Error attempting to play the video:', error);
            });
        }
        return () => {
            // Cleanup on unmount
            if (videoElement) {
                videoElement.pause();
                videoElement.srcObject = null;
            }
        };
    }, [participant]);
    return (_jsx(Box, Object.assign({ className: classes.videoWrapper }, { children: _jsx("video", { id: screenShareVideoElementId, autoPlay: true, playsInline: true, className: classes.video }) })));
}
