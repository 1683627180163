import { useCallback, useEffect, useRef, useState } from 'react';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import hark from 'hark';
import _ from 'lodash';
// Returns (one) active speaker and the list of active speakers
export function useActiveSpeaker() {
    const { meeting } = useDyteMeeting();
    const [activeSpeaker, setActiveSpeaker] = useState(null);
    // Data for monitoring speaking participants
    const [speakingParticipants, setSpeakingParticipants] = useState([]);
    const harkInstances = useRef({});
    const audioUpdateListeners = useRef({});
    const handleSpeaking = (participant) => {
        setSpeakingParticipants((prev) => [...prev, participant]);
        setActiveSpeaker(participant);
    };
    const handleStoppedSpeaking = (participant) => {
        setSpeakingParticipants((prev) => {
            const remainingSpeakers = _.reject(prev, (p) => p.id === participant.id);
            if (remainingSpeakers.length > 0) {
                setActiveSpeaker(remainingSpeakers[0]);
            }
            return remainingSpeakers;
        });
    };
    const cleanupHarkInstance = (participantId) => {
        const harkInstance = harkInstances.current[participantId];
        if (harkInstance) {
            harkInstance.off('speaking');
            harkInstance.off('stopped_speaking');
            harkInstance.stop();
            delete harkInstances.current[participantId];
        }
    };
    const setupHarkInstance = useCallback((participant, audioEnabled, audioTrack) => {
        cleanupHarkInstance(participant.id);
        if (audioEnabled && audioTrack != null) {
            const stream = new MediaStream();
            stream.addTrack(audioTrack);
            const harkInstance = hark(stream);
            harkInstance.on('speaking', () => handleSpeaking(participant));
            harkInstance.on('stopped_speaking', () => handleStoppedSpeaking(participant));
            harkInstances.current[participant.id] = harkInstance;
        }
        else {
            handleStoppedSpeaking(participant);
        }
    }, []);
    const startParticipantTracking = useCallback((participant) => {
        setupHarkInstance(participant, participant.audioEnabled, participant.audioTrack);
        const listener = ({ audioEnabled, audioTrack }) => {
            setupHarkInstance(participant, audioEnabled, audioTrack);
        };
        participant.addListener('audioUpdate', listener);
        // Store the listener so it can be removed later
        audioUpdateListeners.current[participant.id] = listener;
    }, [setupHarkInstance]);
    const stopParticipantTracking = useCallback((participant) => {
        handleStoppedSpeaking(participant);
        cleanupHarkInstance(participant.id);
        const listener = audioUpdateListeners.current[participant.id];
        if (listener) {
            participant.removeListener('audioUpdate', listener);
            delete audioUpdateListeners.current[participant.id];
        }
    }, []);
    // Initialization (Sets up participant movement listeners, and audio track listeners)
    useEffect(() => {
        const participants = [
            ...meeting.participants.active.toArray(),
            meeting.self,
        ];
        // We may already have some participants, start tracking their audio
        participants.forEach((participant) => {
            startParticipantTracking(participant);
        });
        const participantJoined = (participant) => {
            startParticipantTracking(participant);
        };
        const participantLeft = (participant) => {
            stopParticipantTracking(participant);
        };
        meeting.participants.joined.on('participantJoined', participantJoined);
        meeting.participants.joined.on('participantLeft', participantLeft);
        // Remove event listeners on unmount
        return () => {
            const currentParticipants = [
                ...meeting.participants.active.toArray(),
                meeting.self,
            ];
            currentParticipants.forEach((participant) => {
                stopParticipantTracking(participant);
            });
            meeting.participants.joined.removeListener('participantJoined', participantJoined);
            meeting.participants.joined.removeListener('participantLeft', participantLeft);
        };
        // To be run only once
    }, [
        meeting.participants,
        meeting.self,
        startParticipantTracking,
        stopParticipantTracking,
    ]);
    return [activeSpeaker, speakingParticipants];
}
