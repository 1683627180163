import {
  DISPLAYABLE_PRONOUNS_START_INDEX,
  PRONOUNS,
} from '@screens/CheckIn/ScreenCheckIn/ScreenCheckIn';
import IPatron from '@typings/IPatron';
import { useMemo } from 'react';

export function usePatronDisplayName(patron: IPatron): string {
  const pronoun = patron?.properties?.pronoun;
  const firstName = patron?.properties?.firstName;

  return useMemo(() => {
    const isDisplayablePronoun =
      pronoun &&
      (PRONOUNS.en.indexOf(pronoun) >= DISPLAYABLE_PRONOUNS_START_INDEX ||
        PRONOUNS.fr.indexOf(pronoun) >= DISPLAYABLE_PRONOUNS_START_INDEX);

    return isDisplayablePronoun ? `${firstName} (${pronoun})` : firstName;
  }, [firstName, pronoun]);
}
