import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { DyteControlbarButton, DyteTooltip } from '@dytesdk/react-ui-kit';
import { settingsCallSvgString } from '../../iconStrings';
import useLocale from '../../hooks/useLocale';
const useStyles = makeStyles((theme) => ({
    customControlButton: {
        height: theme.spacing(4.5),
        minWidth: theme.spacing(6.5),
    },
}));
export default function SettingsButton({ className, onClick, }) {
    const classes = useStyles();
    const languagePack = useLocale();
    return (_jsx(DyteTooltip, Object.assign({ className: className, label: languagePack('settings'), variant: "secondary", placement: "bottom" }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('settings'), icon: settingsCallSvgString, onClick: onClick, size: "sm" }) })));
}
