import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteDialog, DyteSettingsAudio, DyteSettingsVideo, DyteSwitch, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { raam } from 'raam-client-lib';
import { useCallback, useMemo } from 'react';
import InQStyleGuide from '../../constants';
import { useMeetingStore } from '../../context';
import useLocale from '../../hooks/useLocale';
import { useRoomConfig } from '../../hooks/useRoomConfig';
import { DisplayMeta } from './DisplayMeta';
const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
        overflow: 'hidden',
    },
    dialogContent: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '100%',
        width: theme.spacing(70),
    },
    dialogHeader: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    dialogBody: {
        flex: 1,
        marginBottom: theme.spacing(3),
        overflow: 'auto',
        padding: `0 ${theme.spacing(3)}px`,
    },
    select: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.5),
        border: 'none',
        color: InQStyleGuide.white,
        fontSize: '16px',
        padding: theme.spacing(1.5),
        width: '100%',
    },
    switch: {
        boxSizing: 'border-box',
    },
}));
export default function SettingsDialog() {
    const { isBlurred, isMobile, isSettingsVisible, setHideSettings, toggleBlur, } = useMeetingStore();
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const { roomConfig } = useRoomConfig(meeting.meta.meetingId, meeting.self.id);
    const languageCode = roomConfig.roomLanguage;
    const primaryCaptionLanguages = languagePack('PrimaryCaptionLanguages');
    const otherCaptionLanguages = languagePack('OtherCaptionLanguages');
    const primaryLanguages = useMemo(() => _.map(primaryCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [primaryCaptionLanguages]);
    const otherLanguages = useMemo(() => _.map(otherCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [otherCaptionLanguages]);
    const onChangeLanguage = useCallback((e) => {
        const nextLanguageCode = _.get(e, 'target.value', '');
        raam.meetingRoom.setRoomLanguage(meeting.meta.meetingId, nextLanguageCode);
    }, [meeting.meta.meetingId]);
    const onBlurSwitchChange = useCallback((e) => {
        // Note: DyteSwitch calls this whenever switch value changes or initializes.
        // Make sure we react to direct user action only
        if (isSettingsVisible && e.detail !== isBlurred && toggleBlur) {
            toggleBlur();
        }
    }, [isBlurred, isSettingsVisible, toggleBlur]);
    const onDialogClose = useCallback(() => {
        setHideSettings();
    }, [setHideSettings]);
    return (_jsx(DyteDialog, Object.assign({ className: classes.dialog, open: isSettingsVisible, onDyteDialogClose: onDialogClose }, { children: _jsxs(Box, Object.assign({ className: classes.dialogContent }, { children: [_jsxs(Box, Object.assign({ className: classes.dialogHeader }, { children: [_jsx("h2", { children: languagePack('settings') }), _jsx("hr", {})] })), _jsxs(Box, Object.assign({ className: classes.dialogBody }, { children: [_jsx("h3", { children: languagePack('video') }), _jsx(DyteSettingsVideo, { meeting: meeting, t: languagePack }), !isMobile && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsx("span", { children: languagePack('blur_toggle') }), _jsx(DyteSwitch, { className: classes.switch, checked: isBlurred, onDyteChange: onBlurSwitchChange })] }))), _jsx("hr", {}), _jsx("h3", { children: languagePack('audio') }), _jsx(DyteSettingsAudio, { meeting: meeting, t: languagePack }), _jsx("hr", {}), _jsx("h3", { children: languagePack('captions') }), _jsx("span", { children: languagePack('captions_select') }), _jsx(Box, Object.assign({ display: "flex" }, { children: _jsxs("select", Object.assign({ title: languagePack('captions_select'), id: "caption-language-select", className: classes.select, value: languageCode, onChange: onChangeLanguage }, { children: [primaryLanguages, otherLanguages] })) })), _jsx("hr", {}), _jsx(DisplayMeta, { data: meeting.meta })] }))] })) })));
}
