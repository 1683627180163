var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { raam } from 'raam-client-lib';
import { createContext, useCallback, useContext, useMemo, } from 'react';
import { useImmer } from 'use-immer';
import { ParticipantsViews } from '../components';
import { BACKGROUND_BLUR_SETTINGS_KEY, DEFAULT_BACKGROUND_BLUR, } from '../constants';
import { useIsMobile, useLocalStorageState } from '../hooks';
import { useMediaDevices } from '../hooks/useMediaDevices';
import { useRoomConfig } from '../hooks/useRoomConfig';
export const MeetingStoreContext = createContext(undefined);
export function MeetingStoreProvider({ children }) {
    const { meeting } = useDyteMeeting();
    const meetingName = meeting.meta.meetingId;
    const { roomConfig } = useRoomConfig(meetingName, meeting.self.id);
    const isMobile = useIsMobile();
    const { canSwitchCamera, switchCamera } = useMediaDevices();
    const [isBlurred, setIsBlurred] = useLocalStorageState(BACKGROUND_BLUR_SETTINGS_KEY, DEFAULT_BACKGROUND_BLUR);
    const [showParticipantsList, setShowParticipants] = useImmer(false);
    const [isSettingsVisible, setIsSettingsVisible] = useImmer(false);
    const [participantsView, setParticipantsView] = useImmer(ParticipantsViews.GRID);
    const [isImmersiveMode, setIsImmersiveMode] = useImmer(false);
    const toggleImmersiveMode = useCallback(() => {
        setIsImmersiveMode((activeImmersiveMode) => !activeImmersiveMode);
    }, [setIsImmersiveMode]);
    const toggleBlur = useCallback(() => {
        setIsBlurred((b) => !b);
    }, [setIsBlurred]);
    const setHideParticipantsList = useCallback(() => {
        setShowParticipants(false);
    }, [setShowParticipants]);
    const setShowParticipantsList = useCallback(() => {
        setShowParticipants(true);
    }, [setShowParticipants]);
    const toggleParticipantsList = useCallback(() => {
        setShowParticipants((show) => !show);
    }, [setShowParticipants]);
    const setShowSettings = useCallback(() => {
        setIsSettingsVisible(true);
    }, [setIsSettingsVisible]);
    const setHideSettings = useCallback(() => {
        setIsSettingsVisible(false);
    }, [setIsSettingsVisible]);
    const setGridParticipantsView = useCallback(() => {
        setParticipantsView(ParticipantsViews.GRID);
    }, [setParticipantsView]);
    const setSpeakerParticipantsView = useCallback(() => {
        setParticipantsView(ParticipantsViews.SPEAKER);
    }, [setParticipantsView]);
    const sendHandRaisingBroadcast = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!roomConfig.isHandRaised) {
            try {
                yield meeting.participants.broadcastMessage('hand-raise', {
                    name: meeting.self.name,
                    id: meeting.self.id,
                });
            }
            catch (error) {
                console.error('Error occurred while raising hand', error);
            }
        }
    }), [
        meeting.participants,
        meeting.self.id,
        meeting.self.name,
        roomConfig.isHandRaised,
    ]);
    const onCaptionToggle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        // TODO further implement captioning
        try {
            yield raam.meetingRoom.setCaptioningState(meeting.meta.meetingId, meeting.self.id, !roomConfig.isCaptioningEnabled);
        }
        catch (error) {
            console.error('Error occurred while toggling captioning', error);
        }
    }), [meeting.meta.meetingId, meeting.self.id, roomConfig.isCaptioningEnabled]);
    const onHandRaiseToggle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Promise.all([
                raam.meetingRoom.setHandRaise(meeting.meta.meetingId, meeting.self.id, !roomConfig.isHandRaised),
                sendHandRaisingBroadcast(),
            ]);
        }
        catch (error) {
            console.error('Error occurred while raising hand', error);
        }
    }), [
        meeting.self.id,
        meeting.meta.meetingId,
        roomConfig.isHandRaised,
        sendHandRaisingBroadcast,
    ]);
    const contextValue = useMemo(() => ({
        isBlurred,
        isImmersiveMode,
        isMobile,
        isSettingsVisible,
        onCaptionToggle,
        onHandRaiseToggle,
        participantsView,
        setGridParticipantsView,
        setHideParticipantsList,
        setHideSettings,
        setIsSettingsVisible,
        setParticipantsView,
        setShowParticipantsList,
        setShowSettings,
        setSpeakerParticipantsView,
        showParticipantsList,
        switchCamera,
        toggleBlur,
        toggleImmersiveMode,
        toggleParticipantsList,
        canSwitchCamera,
    }), [
        isBlurred,
        isImmersiveMode,
        isMobile,
        isSettingsVisible,
        onCaptionToggle,
        onHandRaiseToggle,
        participantsView,
        setGridParticipantsView,
        setHideParticipantsList,
        setHideSettings,
        setIsSettingsVisible,
        setParticipantsView,
        setShowParticipantsList,
        setShowSettings,
        setSpeakerParticipantsView,
        showParticipantsList,
        switchCamera,
        toggleBlur,
        toggleImmersiveMode,
        toggleParticipantsList,
        canSwitchCamera,
    ]);
    return (_jsx(MeetingStoreContext.Provider, Object.assign({ value: contextValue }, { children: children })));
}
export function useMeetingStore() {
    const context = useContext(MeetingStoreContext);
    if (!context) {
        throw new Error('useMeetingStore must be used within a MeetingStoreProvider');
    }
    return context;
}
