import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteScreenshareView } from '@dytesdk/react-ui-kit';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useMemo } from 'react';
import InQStyleGuide from '../../../constants';
import { useMeetingStore } from '../../../context';
import { useRoomConfig } from '../../../hooks';
import { useMainSpeakers } from '../../../hooks/useMainSpeakers';
import { useDeviceOrientation } from '../../DeviceOrientationProvider';
import ParticipantTile, { NameTagPosition } from '../../shared/ParticipantTile';
import { ImmersiveSelfView } from '../ImmersiveSelfView/ImmersiveSelfView';
const useStyles = makeStyles((theme) => ({
    viewContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    portraitViewContainer: {
        flexDirection: 'column',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    landscapeViewContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    sharedView: {
        display: 'flex',
        flex: 3,
        padding: theme.spacing(1),
    },
    screenshareTile: {
        background: 'transparent',
    },
    participantsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: theme.spacing(2),
    },
    portraitParticipantsContainer: {
        flexDirection: 'column',
    },
    tileWrapper: {
        position: 'relative',
        background: InQStyleGuide.grey.light,
        borderRadius: theme.spacing(2),
        flex: 1,
    },
    mainTileWrapper: {
        flex: 2,
    },
    participantTile: {
        aspectRatio: 'unset',
        height: '100%',
    },
}));
export function ImmersiveView() {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const { isMobile } = useMeetingStore();
    const { isPortrait } = useDeviceOrientation();
    const { roomConfig: { handsRaised }, } = useRoomConfig(meeting.meta.meetingId, meeting.self.id);
    const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
    const selfSharing = useDyteSelector((m) => m.self.screenShareEnabled);
    const participantsCount = useDyteSelector((m) => m.participants.count) + 1;
    const [hostSpeaker, thirdSpeaker] = useMainSpeakers();
    const isHostHandRaised = useMemo(() => (hostSpeaker && handsRaised.includes(hostSpeaker.id)) || false, [handsRaised, hostSpeaker]);
    const isThirdSpeakerHandRaised = useMemo(() => (thirdSpeaker && handsRaised.includes(thirdSpeaker.id)) || false, [handsRaised, thirdSpeaker]);
    const otherSharing = joinedParticipants.find((p) => p.screenShareEnabled);
    const sharingParticipant = selfSharing ? meeting.self : otherSharing;
    const nameTagPosition = isPortrait || isMobile ? NameTagPosition.LEFT : NameTagPosition.CENTER;
    return (_jsxs(Box, Object.assign({ className: clsx(classes.viewContainer, {
            [classes.portraitViewContainer]: isPortrait,
            [classes.landscapeViewContainer]: !isPortrait && isMobile,
        }) }, { children: [sharingParticipant && (_jsx(Box, Object.assign({ className: classes.sharedView }, { children: _jsx(DyteScreenshareView, { meeting: meeting, participant: sharingParticipant, className: classes.screenshareTile }) }))), _jsxs(Box, Object.assign({ className: clsx(classes.participantsContainer, {
                    [classes.portraitParticipantsContainer]: (!isPortrait && sharingParticipant) ||
                        (isPortrait && !sharingParticipant),
                }) }, { children: [_jsx(ImmersiveSelfView, {}), hostSpeaker && meeting.self.id !== hostSpeaker.id && (_jsx(Box, Object.assign({ className: classes.tileWrapper }, { children: _jsx(ParticipantTile, { participant: hostSpeaker, meeting: meeting, className: classes.participantTile, namePosition: nameTagPosition, handRaised: isHostHandRaised }, hostSpeaker.id) }))), (participantsCount > 2 || thirdSpeaker) && (_jsx(Box, Object.assign({ className: clsx(classes.tileWrapper, {
                            [classes.mainTileWrapper]: !isPortrait && sharingParticipant && !isMobile,
                        }) }, { children: thirdSpeaker && (_jsx(ParticipantTile, { participant: thirdSpeaker, meeting: meeting, className: classes.participantTile, namePosition: nameTagPosition, handRaised: isThirdSpeakerHandRaised }, thirdSpeaker.id)) })))] }))] })));
}
