import { SinglePanelLayout } from '@components/Layout';
import useApplicationConfig from '@hooks/useApplicationConfig';
import useClinicConfig from '@hooks/useClinicConfig';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { useExtendedTranslation } from '@services/i18nService';
import { AppStates } from '@typings/IAppState';
import i18next from 'i18next';
import _ from 'lodash';
import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const AppStatesToMessages = {
  OPEN: 'msgOpen',
  BREAK: 'msgBreak',
  FORCECLOSE: 'msgForcedClose',
  CLOSED: 'msgClosed',
};

const useStyles = makeStyles(() => ({
  aboutTitleTypography: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  checkinTitleTypography: {
    fontWeight: 'bold',
  },
  aboutButton: {
    padding: 0,
    backgroundColor: 'transparent',
  },
}));

function FrontPage({
  onNext,
  appState,
  onAbout,
}: {
  appState: AppStates;
  onNext: Function;
  onAbout?: Function;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { REACT_APP_IS_VCDS } = useFeatureFlags();
  const applicationConfig = useApplicationConfig();
  const messages = _.get(applicationConfig, AppStatesToMessages[appState]);
  const clinicConfig = useClinicConfig();
  const t = useExtendedTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const CheckInButton = () => (
    <Button
      variant="contained"
      color="primary"
      startIcon={hasSubmitted && <CircularProgress color="inherit" size={15} />}
      disabled={hasSubmitted}
      onClick={() => {
        setHasSubmitted(true);
        onNext();
      }}
    >
      {t('welcome.checkin.button')}
    </Button>
  );

  const Footer = (
    <Grid container justifyContent="center">
      <Grid item>
        <CheckInButton />
      </Grid>
      <Box width="100%" height={15} />
      <Grid item>
        <Button
          className={classes.aboutButton}
          size="small"
          onClick={() => onAbout && onAbout()}
        >
          <Typography
            color="secondary"
            className={classes.aboutTitleTypography}
            variant="body2"
          >
            {t('about.title')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  const Content = REACT_APP_IS_VCDS ? (
    <>
      <Box color={theme.palette.primary.main}>
        {ReactHtmlParser(messages.primary[i18next.language as 'en' | 'fr'])}
      </Box>
      <Box color={theme.palette.primary.main}>
        {ReactHtmlParser(messages.secondary[i18next.language as 'en' | 'fr'])}
      </Box>
      {appState === AppStates.OPEN && (
        <Grid container justifyContent="center">
          <Grid item>
            <CheckInButton />
          </Grid>
        </Grid>
      )}
    </>
  ) : (
    <>
      <span style={{ color: theme.palette.primary.main }}>
        {ReactHtmlParser(messages.primary[i18next.language as 'en' | 'fr'])}
      </span>
      {messages.displayCheckInHours && (
        <>
          <Typography
            className={classes.checkinTitleTypography}
            variant="body2"
            color="primary"
            align="center"
          >
            {t('welcome.checkin.hours.title')}
          </Typography>
          <Box textAlign="center" color={theme.palette.primary.main}>
            {ReactHtmlParser(
              applicationConfig.msgCheckinHours[i18next.language as 'en' | 'fr']
            )}
          </Box>
        </>
      )}
      <Box color={theme.palette.primary.main}>
        {ReactHtmlParser(messages.secondary[i18next.language as 'en' | 'fr'])}
      </Box>
    </>
  );

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        footer={
          appState === AppStates.OPEN && !REACT_APP_IS_VCDS ? Footer : undefined
        }
        logo={clinicConfig.logoName}
      >
        {Content}
      </SinglePanelLayout>
    </Box>
  );
}

export default FrontPage;
