const _ = require("lodash");
const { invokeApi } = require("./utils");

const createCSV = (header, data) => {
  return _.join(
    [_.join(header, ","), ..._.map(data, (row) => _.join(row, ","))],
    "\n"
  );
};

const downloadReports = async (
  { visitReport, denialReport, queueReport, eventReport },
  from,
  to
) => {
  let mergedData = {};

  do {
    let content = await invokeApi("raam.downloadReports")({
      visitReport,
      denialReport,
      queueReport,
      eventReport,
      from,
      to,
    });
    from = content.nextFrom;

    _.each(_.pull(_.keys(content), "nextFrom", "queryTo"), (key) => {
      let mData = mergedData[key] ?? { header: null, data: [] };
      let { data, header } = content[key];

      mData.header ??= header;
      mData.data.push(...data);

      mergedData[key] = mData;
    });
  } while (from);

  return _.map(mergedData, ({ header, data }, key) => {
    let s = createCSV(header, data);
    return [key, s];
  });
};

const adaptGroupReport = (rawData) => {
  return {
    date: rawData?.dateStr ?? "",
    csvData: rawData?.csvData ?? "",
  };
};

const downloadGroupReport = (meetingTypeInclusion = "ALL") => {
  return invokeApi("raam.downloadGroupReport")(meetingTypeInclusion).then(
    (rawData) => adaptGroupReport(rawData)
  );
};

const downloadAnonymizedGroupReport = (
  meetingTypeInclusion,
  startDate,
  endDate
) => {
  return invokeApi("raam.downloadAnonymizedGroupReport")(
    meetingTypeInclusion,
    startDate,
    endDate
  ).then((rawData) => adaptGroupReport(rawData));
};

module.exports = {
  downloadReports,
  downloadGroupReport,
  downloadAnonymizedGroupReport,
};
