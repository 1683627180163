import { jsx as _jsx } from "react/jsx-runtime";
import { DyteIcon } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { networkQualitySvgString } from '../../iconStrings';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';
export function NetworkQualityVisualizer() {
    const { meeting } = useDyteMeeting();
    const [networkScore, setNetworkScore] = useImmer(3);
    useEffect(() => {
        const handleNetworkQualityUpdate = ({ kind, isScreenshare, score, }) => {
            if (kind === 'video' || isScreenshare) {
                const adjustedScore = Math.max(1, Math.round(score / 2));
                setNetworkScore(adjustedScore);
            }
        };
        meeting.self.addListener('mediaScoreUpdate', handleNetworkQualityUpdate);
        return () => {
            meeting.self.removeListener('mediaScoreUpdate', handleNetworkQualityUpdate);
        };
    }, [meeting.self, setNetworkScore]);
    return _jsx(DyteIcon, { icon: networkQualitySvgString[networkScore], size: "md" });
}
