import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function Connecting() {
  const { t } = useTranslation();
  return (
    <Container maxWidth="xs" style={{ height: '100%' }}>
      <Grid container alignItems="center" style={{ height: '100%' }}>
        <Grid item xs>
          <Box
            bgcolor="white"
            padding={2}
            borderRadius={8}
            boxShadow="0px 2px 4px 0px rgba(40, 42, 43, 0.3)"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ height: '100%' }}
            >
              <div>
                <CircularProgress variant="indeterminate" />
              </div>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    color: 'black',
                  }}
                >
                  {t('meeting.preJoinScreen')}
                </Typography>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Connecting;
