import { jsx as _jsx } from "react/jsx-runtime";
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { DyteGrid, defaultConfig } from '@dytesdk/react-ui-kit';
import useLocale from '../../hooks/useLocale';
import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { useInjectParticipantTileStyle, useRoomConfig } from '../../hooks';
const config = Object.assign({}, defaultConfig);
if (config.root) {
    config.root['dyte-participant-tile'] = config.root['dyte-participant-tile'].children;
}
/**
 * Participants Grid View
 * Currently it just uses the default DyteGrid component
 * TODO replace with custom code, to match design (eg. highlighting active speaker, styleing name tag, customize screen share)
 */
export default function GridView({ tiles, }) {
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const [, speakers] = useActiveSpeaker();
    const { roomConfig: { handsRaised }, } = useRoomConfig(meeting.meta.meetingId, meeting.self.id);
    const speakerIds = speakers.map((s) => s.id);
    // Add active speaker / raised hand highlight for grid view participants
    useInjectParticipantTileStyle(tiles, speakerIds, handsRaised);
    return _jsx(DyteGrid, { meeting: meeting, config: config, t: languagePack });
}
