import { ISurveyData } from '@typings/index';

export const DEFAULT_USER_DATA: ISurveyData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  allowSMS: true,
  email: '',
  currentAddress: '',
  inRegion: false,
  rememberMe: false,

  isFirstTime: false,
  haveAppointment: false,

  alcoholQ: undefined,
  opioidQ: undefined,
  stimulantsQ: undefined,
  otherSubstancesQ: undefined,
  mentalHealthQ: undefined,

  homeAddress: undefined,
  familyDoctor: undefined,
  pregnantQ: 'No/Does not apply',

  healthCardNumber: undefined,
  healthCardBlob: undefined,

  healthIdNumber: undefined,
  registrationNumber: undefined,
  dobDay: undefined,
  dobMonth: undefined,
  dobYear: undefined,

  userAgent: undefined,
  screenWidth: undefined,
  screenHeight: undefined,
  screenOrientation: undefined,

  hasNoHealthCard: false,

  pronoun: '',
};

export const DEFAULT_INIT_PATRON_VALUE = {
  ...DEFAULT_USER_DATA,
  currentAddress: '',
};

export const VCDS_LOCAL_STORAGE_PROPERTIES = [
  'firstName',
  'lastName',
  'dobYear',
  'dobMonth',
  'dobDay',
  'pronoun',
  'rememberMe',
  'phoneNumber',
  'allowSMS',
];

export const LOCAL_STORAGE_PROPERTIES = [
  'firstName',
  'lastName',
  'phoneNumber',
  'allowSMS',
  'email',
  'inRegion',
  'rememberMe',
];
