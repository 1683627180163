import { jsx as _jsx } from "react/jsx-runtime";
import { DyteIcon } from '@dytesdk/react-ui-kit';
import { Box, makeStyles } from '@material-ui/core';
import { RAISED_HAND_COLOUR } from '../../constants';
import { handRaisedSvgString } from '../../iconStrings';
import { applyColor } from '../../utils/utils';
const useStyles = makeStyles((theme) => ({
    handRaisedContainer: {
        position: 'absolute',
        top: 0,
    },
    handRaisedIconContainer: {
        position: 'absolute',
        height: 'fit-content',
        width: 'fit-content',
        left: theme.spacing(1),
        top: theme.spacing(1),
    },
}));
export function HandRaisedIndicator({ handRaised, hideIcon = true, }) {
    const classes = useStyles();
    return (_jsx(Box, Object.assign({ id: "raise-hand-container", className: classes.handRaisedContainer, hidden: !handRaised }, { children: !hideIcon && (_jsx(Box, Object.assign({ className: classes.handRaisedIconContainer }, { children: _jsx(DyteIcon, { icon: applyColor(handRaisedSvgString, RAISED_HAND_COLOUR), size: "md" }) }))) })));
}
