import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteIcon } from '@dytesdk/react-ui-kit';
import { Box, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useState } from 'react';
import { copySvgString } from '../../iconStrings';
const useStyles = makeStyles((theme) => createStyles({
    container: {
        position: 'relative',
        display: 'inline-block',
        width: '100%',
    },
    pre: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        overflow: 'auto',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        position: 'relative',
        paddingTop: theme.spacing(4),
    },
    button: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
    },
}));
export function DisplayMeta({ data }) {
    const classes = useStyles();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(data, null, 2)).then(() => {
            setSnackbarOpen(true);
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: toggleVisibility }, { children: isVisible ? 'Hide Meta' : 'Show Meta' })), isVisible && (_jsxs(_Fragment, { children: [_jsx("pre", Object.assign({ className: classes.pre }, { children: JSON.stringify(data, null, 2) })), _jsx(Tooltip, Object.assign({ title: "Copy to Clipboard" }, { children: _jsx(IconButton, Object.assign({ className: classes.button, color: "primary", onClick: handleCopy }, { children: _jsx(DyteIcon, { icon: copySvgString, size: "sm" }) })) })), _jsx(Snackbar, { open: snackbarOpen, autoHideDuration: 3000, onClose: handleCloseSnackbar, message: "Copied to clipboard" })] }))] })));
}
