/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
import { MouseEventHandler } from 'react';
import { SinglePanelWithMessaging } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import InQStyleGuide from '../../constants';

const LeaveQueueButton = withStyles({
  root: {
    padding: 0,
  },
  label: {
    color: InQStyleGuide.blue.bright,
    fontWeight: 600,
    textDecoration: 'underline',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: '14px',
  },
})(Button);

const baseStyle = {
  root: {
    color: InQStyleGuide.blue.medium,
    fontSize: '0.875rem',
  },
};

const StyledTypography = withStyles(baseStyle)(Typography);

const ItalicStyledTypography = withStyles({
  ...baseStyle,
  root: {
    ...baseStyle.root,
    fontStyle: 'italic',
  },
})(Typography);

const StyledTitle = withStyles({
  root: {
    ...baseStyle.root,
    fontSize: '1.5rem',
    textAlign: 'center',
  },
})(Typography);

const StyledDisclaimer = withStyles({
  root: {
    ...baseStyle.root,
    fontStyle: 'italic',
    textAlign: 'center',
  },
})(Typography);

function HoldOnForTriage({
  onLeave,
  patron,
}: {
  onLeave: MouseEventHandler<HTMLElement>;
  patron: any;
}) {
  const t = useExtendedTranslation();

  return (
    <Box display="flex" flex={1} className="thank-you-for-waiting">
      <SinglePanelWithMessaging
        patron={patron}
        footer={
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <StyledDisclaimer variant="body2">
                {t('serviceDisclaimer')}
              </StyledDisclaimer>
            </Grid>
            <Grid item>
              <LeaveQueueButton variant="text" onClick={onLeave}>
                {t('thankyou.leave')}
              </LeaveQueueButton>
            </Grid>
          </Grid>
        }
      >
        <StyledTitle variant="h5">{t('holdon.title')}</StyledTitle>
        <Box flex={1} mt={2} style={{ wordWrap: 'break-word' }}>
          <StyledTypography>{t('holdon.text.body1')}</StyledTypography>
          <br />
          <StyledTypography>{t('holdon.text.body2')}</StyledTypography>
          <br />
          <ItalicStyledTypography>
            {t('holdon.text.body3')}
          </ItalicStyledTypography>
        </Box>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default HoldOnForTriage;
