import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SinglePanelLayout } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';

function LinkEnd({ success }: { success: boolean }) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();

  const message = success ? t('linkend.success') : t('linkend.error');
  return (
    <SinglePanelLayout logo={clinicConfig.logoName}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary" align="center">
            {message}
          </Typography>
        </Grid>
      </Grid>
    </SinglePanelLayout>
  );
}

export default LinkEnd;
