/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isProd } from './env';
const defaultOptions = {
    logProd: false,
    color: '#007ACC',
    level: 'info',
};
export function logger(value, comment, options) {
    const finalOptions = Object.assign(Object.assign({}, defaultOptions), options);
    if (!console) {
        return;
    }
    if (isProd && !finalOptions.logProd) {
        return;
    }
    const logFunction = {
        info: console.log,
        warn: console.warn,
        error: console.error,
        trace: console.trace,
        debug: console.debug,
        log: console.log,
    }[finalOptions.level];
    const commentText = comment || '';
    logFunction(`%c ====================================\n[${finalOptions.level.toUpperCase()}] - ${commentText}\n`, `color: ${finalOptions.color}`, value, '\n====================================');
}
