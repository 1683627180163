import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useChatContext } from '@contexts/ChatContext';
import { MeetingContextProvider } from '@contexts/MeetingContext';

import usePatron from '@hooks/usePatron';

import {
  CompleteSession,
  Ejected,
  HoldOnForTriage,
  LeaveQueue,
  Meeting,
  Paused,
  ScreenPleaseWait,
  Survey as SurveyScreen,
  ThankYouForWaiting,
  ValidatingDetail,
  VisitTimer,
} from '@screens/index';

import IPatronIds from '@typings/IPatronIds';

enum Screen {
  Loading, // This screen state exist to avoid flickering. This will be blank page
  WaitingChart,
  Waiting,
  WaitingTriage, // Hold On screen for patient while in triage
  Countdown,
  Rejoin,
  InMeeting,
  PausedMeeting,
  HasBeenEjected,
  Survey,
  Completed,
  Leaving,
  Unload,
}

const StateToScreen = {
  WAITING_FOR_CHART: Screen.WaitingChart,
  WAITING_FOR_TRIAGE: Screen.WaitingTriage,
  WAITING: Screen.Waiting,
  MEETING_REQUEST: Screen.Countdown,
  POST_TRIAGE: Screen.Waiting,
  IN_MEETING: Screen.InMeeting,
  IN_GROUP_MEETING: Screen.InMeeting,
  IN_BREAKOUT: Screen.InMeeting,
  PAUSED_MEETING: Screen.PausedMeeting,
  EJECTED: Screen.HasBeenEjected,
  IN_SURVEY: Screen.Survey,
  COMPLETED: Screen.Completed,
  LEFT: Screen.Unload,
};

const getScreenFromState = (s: string): Screen =>
  _.get(StateToScreen, s, Screen.Loading);

export default function StatesScreen({
  patron: patronIds,
  unloadPatron,
}: {
  patron: IPatronIds;
  unloadPatron: () => void;
}) {
  const [screen, setScreen] = useState<Screen>(Screen.Loading);
  const [patron, inError] = usePatron(patronIds);

  const { updateShowMessaging } = useChatContext();

  const isOn = (expected: Screen) => screen === expected;
  const goTo = (newScreen: Screen) => () => setScreen(newScreen);
  const displayWhen = (expected: Screen, elements: JSX.Element) =>
    isOn(expected) && elements;

  useEffect(() => {
    setScreen(getScreenFromState(patron?.properties?.state));
  }, [patron?.properties?.state]);

  useEffect(() => {
    if (inError) {
      unloadPatron();
    }
  }, [inError, unloadPatron]);

  useEffect(() => {
    if (screen === Screen.Unload) {
      unloadPatron();
    }
  }, [unloadPatron, screen]);

  useEffect(() => {
    updateShowMessaging(false);
  }, [screen, updateShowMessaging]);

  return (
    <MeetingContextProvider patron={patron}>
      {displayWhen(
        Screen.Loading,
        <ScreenPleaseWait message_key="pleasewait.title2" />
      )}

      {displayWhen(
        Screen.WaitingChart,
        <ValidatingDetail patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(
        Screen.WaitingTriage,
        <HoldOnForTriage patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(
        Screen.Waiting,
        <ThankYouForWaiting patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(Screen.Countdown, <VisitTimer patron={patron} />)}

      {displayWhen(
        Screen.InMeeting,
        <Meeting
          patron={patron}
          onContinue={() => {
            setScreen(Screen.Waiting);
          }}
        />
      )}

      {displayWhen(
        Screen.PausedMeeting,
        <Paused patron={patron} onLeave={goTo(Screen.Leaving)} />
      )}

      {displayWhen(
        Screen.HasBeenEjected,
        <Ejected patron={patron} onClose={() => unloadPatron()} />
      )}

      {displayWhen(Screen.Survey, <SurveyScreen patron={patron} />)}

      {displayWhen(
        Screen.Completed,
        <CompleteSession patron={patron} onComplete={() => unloadPatron()} />
      )}

      {displayWhen(
        Screen.Leaving,
        <LeaveQueue
          patron={patron}
          onCancel={() =>
            setScreen(getScreenFromState(patron?.properties?.state))
          }
        />
      )}
    </MeetingContextProvider>
  );
}
