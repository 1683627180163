import { useCallback, useEffect } from 'react';
export function useToggleMuteParticipants(participantTiles) {
    const toggleMuteForParticipant = useCallback((participant) => () => {
        if (!participant) {
            return;
        }
        if (participant.audioEnabled) {
            // disableAudio is available on both Self and Participant
            if ('disableAudio' in participant) {
                participant.disableAudio();
            }
        }
        else {
            // We can only enable self
            if ('enableAudio' in participant) {
                participant.enableAudio();
            }
        }
    }, []);
    // Now, we can mutate the tiles when they change
    useEffect(() => {
        if (!participantTiles) {
            return;
        }
        for (const tile of participantTiles) {
            const participant = tile.participant;
            const icon = tile.querySelector('dyte-audio-visualizer');
            if (icon) {
                icon.onclick = toggleMuteForParticipant(participant);
            }
        }
    }, [participantTiles, toggleMuteForParticipant]);
}
