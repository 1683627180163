import './CompleteSession.scss';
import React, { MouseEventHandler } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import {
  TextBlockDescription,
  SinglePanelWithMessaging,
} from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useClinicConfig from '../../Hooks/useClinicConfig';

function CompleteSession({
  patron,
  onComplete,
}: {
  patron: any;
  onComplete: MouseEventHandler<HTMLElement>;
}) {
  const t = useExtendedTranslation();

  const clinicConfig = useClinicConfig();
  const hasPhoneExtension = !_.isEmpty(_.get(clinicConfig, 'phoneNoExt', ''));

  return (
    <Box display="flex" flex={1}>
      <SinglePanelWithMessaging
        patron={patron}
        title={
          <Typography variant="h5" color="primary" align="center">
            {t('completeSession.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={onComplete}>
                {t('questionnaire.close')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <TextBlockDescription>
          {t('completeSession.description.one')}
        </TextBlockDescription>

        <TextBlockDescription>
          {t('completeSession.description.three')}
        </TextBlockDescription>

        <TextBlockDescription>
          {t(
            hasPhoneExtension
              ? 'completeSession.description.four'
              : 'completeSession.description.fourNoExt',
            { ...clinicConfig }
          )}
        </TextBlockDescription>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default CompleteSession;
