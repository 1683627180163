import { useMemo, useEffect, useState } from 'react';
import { usePromise } from '.';
import AesGcmEncryptor from '../Services/Encrypt/AesEncrypt';

async function getLocalStorageOrDefault<T>(
  key: string,
  defaultValue: T
): Promise<T> {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }

  try {
    const decrypted = await AesGcmEncryptor.decrypt(stored);
    try {
      return JSON.parse(decrypted) as T;
    } catch (error) {
      console.error(
        'Invalid JSON object in localStorage, returning default value:',
        error
      );
      return defaultValue;
    }
  } catch (decryptError) {
    console.error(
      'Error decrypting value from localStorage, returning default value:',
      decryptError
    );
    return defaultValue;
  }
}

export function useEncryptedLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const dataPromise = useMemo(
    () => getLocalStorageOrDefault(key, defaultValue),
    [key, defaultValue]
  );
  const data = usePromise(defaultValue, dataPromise);

  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    setValue(data);
  }, [data]);

  useEffect(() => {
    AesGcmEncryptor.encrypt(JSON.stringify(value)).then((result) => {
      localStorage.setItem(key, result);
    });
  }, [value, key]);

  return [value, setValue];
}
