import React from 'react';
import './Oops.scss';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import i18next from 'i18next';
import ReactHtmlParser from 'react-html-parser';
import { SinglePanelWithMessaging } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import useApplicationConfig from '../../Hooks/useApplicationConfig';

function Oops({
  patron,
  onContinue,
  onRejoin,
}: {
  patron: any;
  onContinue: Function;
  onRejoin: Function;
}) {
  const t = useExtendedTranslation();
  const applicationConfig = useApplicationConfig();

  return (
    <Box className="meeting-panel">
      <SinglePanelWithMessaging patron={patron}>
        <Grid container alignItems="center" direction="column" spacing={1}>
          <Grid item>
            <Typography paragraph variant="body2" color="primary">
              {ReactHtmlParser(
                applicationConfig.msgVisitComplete.primary[
                  i18next.language as 'en' | 'fr'
                ]
              )}
            </Typography>
          </Grid>
          <Grid item>
            {/* TODO: fix this button */}
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onContinue()}
            >
              {t('meeting.inProgress.continue')}
            </Button>
          </Grid>
          <Grid item>
            <Typography paragraph variant="body2" color="primary">
              {ReactHtmlParser(
                applicationConfig.msgVisitComplete.secondary[
                  i18next.language as 'en' | 'fr'
                ]
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onRejoin()}
            >
              {t('meeting.inProgress.reconnect')}
            </Button>
          </Grid>
        </Grid>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default Oops;
