var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteButton, DyteCameraToggle, DyteControlbarButton, DyteMicToggle, DyteParticipantCount, DyteScreenShareToggle, DyteTooltip, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { raam } from 'raam-client-lib';
import { useCallback } from 'react';
import InQStyleGuide, { RAISED_HAND_COLOUR, TOOLTIP_DELAY, } from '../../constants';
import { useMeetingStore } from '../../context';
import { useRoomConfig } from '../../hooks';
import useIconPack from '../../hooks/useIconPack';
import useLocale from '../../hooks/useLocale';
import { blurSvgString, cameraSwitchSvgString, captionSvgString, chatSvgString, chatWithDotSvgString, endCallSvgString, gridViewSvgString, handRaisedSvgString, speakerViewSvgString, toggleImmersiveModeSvgString, } from '../../iconStrings';
import { applyColor } from '../../utils';
import { useDeviceOrientation } from '../DeviceOrientationProvider';
import { MeetingUserType, ParticipantsViews } from '../MeetingCall/MeetingCall';
import SettingsButton from '../Settings/SettingsButton';
const useStyles = makeStyles((theme) => ({
    controlsContainer: {
        bottom: 0,
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        position: 'absolute',
        pointerEvents: 'none',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        width: '100%',
        zIndex: 3,
        // Shadow for desktop (landscape)
        backgroundColor: InQStyleGuide.grey.medium,
        boxShadow: `0px -${theme.spacing(1)}px ${theme.spacing(1.5)}px 0px rgba(0, 0, 0, 0.25)`,
    },
    portraitControlsContainer: {
        flexDirection: 'column',
        height: '100%',
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    // Used on mobile landscape
    landscapeControlsContainer: {
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    bottomControlsContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        pointerEvents: 'auto',
        width: '100%',
    },
    controlsRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        pointerEvents: 'auto',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
    },
    portraitControlsRow: {
        flexDirection: 'column',
    },
    centralControl: {
        position: 'absolute',
        pointerEvents: 'auto',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    customButton: {
        backgroundColor: InQStyleGuide.black,
        borderRadius: theme.spacing(1),
        width: theme.spacing(7),
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: InQStyleGuide.grey.dark,
        },
    },
    customControlButton: {
        height: 'auto',
        minWidth: theme.spacing(6.5),
    },
    landscapeLeaveControl: {
        bottom: theme.spacing(3),
        position: 'absolute',
    },
    dyteParticipantCount: {
        color: InQStyleGuide.white,
        margin: 0,
    },
    raisedHandContainer: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));
export function MeetingControls({ hasUnreadMessages, hideLeave, onChatToggle, userType, }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const { isBlurred, isMobile, isImmersiveMode, onCaptionToggle, onHandRaiseToggle, participantsView, setGridParticipantsView, setShowSettings, setSpeakerParticipantsView, switchCamera, toggleBlur, toggleParticipantsList, toggleImmersiveMode, canSwitchCamera, } = useMeetingStore();
    const { roomConfig } = useRoomConfig(meeting.meta.meetingId, meeting.self.id);
    const { isPortrait } = useDeviceOrientation();
    const languagePack = useLocale();
    const customIconPack = useIconPack();
    const onEndCall = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!(meeting === null || meeting === void 0 ? void 0 : meeting.self) || !(meeting === null || meeting === void 0 ? void 0 : meeting.leave)) {
            return;
        }
        try {
            yield raam.meetingRoom.setHandRaise(meeting.meta.meetingId, meeting.self.id, false);
            yield meeting.leave();
        }
        catch (error) {
            console.error('Error occurred while leaving the meeting', error);
        }
    }), [meeting]);
    const onToggleImmersiveMode = useCallback(() => {
        if (userType !== MeetingUserType.PATRON) {
            return;
        }
        toggleImmersiveMode();
    }, [toggleImmersiveMode, userType]);
    return (_jsxs(Box, Object.assign({ className: clsx(classes.controlsContainer, {
            [classes.portraitControlsContainer]: isPortrait || isMobile,
            [classes.landscapeControlsContainer]: !isPortrait && isMobile,
        }) }, { children: [_jsxs(Box, Object.assign({ className: clsx(classes.controlsRow, {
                    [classes.portraitControlsRow]: isPortrait || isMobile,
                }) }, { children: [userType !== MeetingUserType.CLINICIAN && (_jsx(DyteTooltip, Object.assign({ id: "raise-hand-tooltip", label: roomConfig.isHandRaised
                            ? languagePack('lower_hand')
                            : languagePack('raise_hand'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { id: "raise-hand-button", className: classes.customControlButton, label: roomConfig.isHandRaised
                                ? languagePack('raise_hand')
                                : languagePack('lower_hand'), icon: applyColor(handRaisedSvgString, roomConfig.isHandRaised
                                ? RAISED_HAND_COLOUR
                                : InQStyleGuide.white), onClick: onHandRaiseToggle, size: "sm" }) }))), userType === MeetingUserType.CLINICIAN && (_jsx(DyteTooltip, Object.assign({ label: languagePack('grid_view'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('grid_view'), icon: applyColor(gridViewSvgString, participantsView === ParticipantsViews.GRID
                                ? InQStyleGuide.lime
                                : InQStyleGuide.white), onClick: setGridParticipantsView, size: "sm" }) }))), userType === MeetingUserType.CLINICIAN && (_jsx(DyteTooltip, Object.assign({ label: languagePack('speaker_view'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('speaker_view'), icon: applyColor(speakerViewSvgString, participantsView === ParticipantsViews.SPEAKER
                                ? InQStyleGuide.lime
                                : InQStyleGuide.white), onClick: setSpeakerParticipantsView, size: "sm" }) }))), _jsx(DyteTooltip, Object.assign({ label: languagePack('participants'), variant: "secondary", placement: "bottom", delay: TOOLTIP_DELAY }, { children: _jsx(DyteButton, Object.assign({ className: classes.customButton, size: "lg", variant: "secondary", onClick: toggleParticipantsList }, { children: _jsx(DyteParticipantCount, { className: classes.dyteParticipantCount, meeting: meeting }) })) })), !!onChatToggle && (_jsx(DyteTooltip, Object.assign({ label: hasUnreadMessages
                            ? languagePack('messaging_unread')
                            : languagePack('messaging'), variant: "secondary", placement: "bottom", delay: TOOLTIP_DELAY, size: "sm" }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('messaging'), icon: hasUnreadMessages
                                ? applyColor(chatWithDotSvgString, InQStyleGuide.lime)
                                : chatSvgString, onClick: onChatToggle, size: "sm" }) }))), _jsx(SettingsButton, { onClick: setShowSettings })] })), !isPortrait && !isMobile && !hideLeave && (_jsx(DyteTooltip, Object.assign({ id: "end-call-tooltip", label: languagePack('disconnect'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY, className: classes.centralControl }, { children: _jsx(DyteControlbarButton, { id: "end-call-button", className: classes.customControlButton, label: languagePack('disconnect'), icon: applyColor(endCallSvgString, InQStyleGuide.red), onClick: onEndCall, size: "sm" }) }))), _jsxs(Box, Object.assign({ className: clsx({
                    [classes.bottomControlsContainer]: canSwitchCamera,
                }) }, { children: [canSwitchCamera && (_jsx(DyteTooltip, Object.assign({ id: "switch-camera-tooltip", label: languagePack('switch_camera'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { id: "switch-camera-button", className: classes.customControlButton, label: languagePack('switch_camera'), icon: cameraSwitchSvgString, onClick: switchCamera, size: "sm" }) }))), _jsxs(Box, Object.assign({ className: clsx(classes.controlsRow, {
                            [classes.portraitControlsRow]: isPortrait || isMobile,
                        }) }, { children: [_jsx(DyteMicToggle, { size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack }), _jsx(DyteCameraToggle, { size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack }), userType === MeetingUserType.PATRON && (_jsx(DyteTooltip, Object.assign({ label: isImmersiveMode
                                    ? languagePack('stop_immersive')
                                    : languagePack('start_immersive'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('captions'), icon: applyColor(toggleImmersiveModeSvgString, isImmersiveMode ? InQStyleGuide.lime : InQStyleGuide.white), onClick: onToggleImmersiveMode, size: "sm" }) }))), !isMobile && (_jsx(DyteTooltip, Object.assign({ id: "toggle-blur-tooltip", label: isBlurred
                                    ? languagePack('disable_blur')
                                    : languagePack('enable_blur'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { id: "toggle-blur-button", className: classes.customControlButton, icon: applyColor(blurSvgString, isBlurred ? InQStyleGuide.lime : InQStyleGuide.white), onClick: toggleBlur, size: "sm" }) }))), _jsx(DyteTooltip, Object.assign({ label: languagePack('start_caption'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('captions'), icon: applyColor(captionSvgString, roomConfig.isCaptioningEnabled
                                        ? InQStyleGuide.lime
                                        : InQStyleGuide.white), onClick: onCaptionToggle, size: "sm" }) })), userType === MeetingUserType.CLINICIAN && (_jsx(DyteScreenShareToggle, { size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack })), (isPortrait || isMobile) && !hideLeave && (_jsx(DyteTooltip, Object.assign({ label: languagePack('disconnect'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: clsx(classes.customControlButton, {
                                        [classes.landscapeLeaveControl]: !isPortrait && isMobile,
                                    }), label: languagePack('disconnect'), icon: applyColor(endCallSvgString, InQStyleGuide.red), onClick: onEndCall, size: "sm" }) })))] }))] }))] })));
}
