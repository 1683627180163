import { sendNotification } from '@dytesdk/react-ui-kit';
import { useEffect } from 'react';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import useLocale from './useLocale';
export function useBroadcastHandRaised() {
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    useEffect(() => {
        if (!meeting) {
            return;
        }
        meeting.participants.on('broadcastedMessage', ({ payload }) => {
            if (!payload) {
                return;
            }
            if (!(payload === null || payload === void 0 ? void 0 : payload.name)) {
                return;
            }
            const notificationObj = {
                id: new Date().getTime().toString(),
                message: `${languagePack('broadcast_raise_hand').replace('{{name}}', payload.name.toString())}`,
                duration: 3000,
            };
            sendNotification(notificationObj, 'message');
        });
        return () => {
            meeting.participants.removeAllListeners('broadcastedMessage');
        };
    }, [languagePack, meeting]);
}
