import { useEffect } from 'react';
export function useMutationObserver(elem, callback, options) {
    useEffect(() => {
        if (!elem) {
            return;
        }
        const observer = new MutationObserver((_mutations, obs) => {
            callback(elem, obs);
        });
        observer.observe(elem, options);
        // Perform an initial callback
        callback(elem, observer);
        return () => observer.disconnect();
    }, [callback, elem, options]);
}
