import _fp from 'lodash/fp';
import { useEffect, useState } from 'react';
import { PRESETS_CLINICIAN } from '../constants';
const isClinician = _fp.flow([
    // Multi-line
    _fp.get('presetName'),
    _fp.includes(_fp.placeholder, PRESETS_CLINICIAN),
]);
const toMeetingParticipant = (p, changes = {}) => Object.assign(p, changes, {
    isClinician: isClinician(p),
});
export function useMeetingParticipants(meeting, callbacks) {
    const [participants, setParticipants] = useState([]);
    useEffect(() => {
        if (!meeting) {
            return setParticipants([]);
        }
        const joined = (participant) => {
            var _a;
            const p = toMeetingParticipant(participant);
            setParticipants(_fp.flow([
                // Multi-line
                _fp.clone,
                _fp.concat(_fp.placeholder, p),
            ]));
            (_a = callbacks === null || callbacks === void 0 ? void 0 : callbacks.onParticipantJoined) === null || _a === void 0 ? void 0 : _a.call(callbacks, p);
        };
        const left = (participant) => {
            var _a;
            const p = toMeetingParticipant(participant);
            setParticipants(_fp.flow([
                // Multiline
                _fp.clone,
                _fp.reject({ id: p.id }),
            ]));
            (_a = callbacks === null || callbacks === void 0 ? void 0 : callbacks.onParticipantLeft) === null || _a === void 0 ? void 0 : _a.call(callbacks, p);
        };
        const updated = (participant, changed) => {
            var _a;
            const p = toMeetingParticipant(participant, changed);
            setParticipants(_fp.flow([
                // Multiline
                _fp.clone,
                _fp.reject({ id: p.id }),
                _fp.concat(_fp.placeholder, toMeetingParticipant(participant, p)),
            ]));
            (_a = callbacks === null || callbacks === void 0 ? void 0 : callbacks.onParticipantChanged) === null || _a === void 0 ? void 0 : _a.call(callbacks, p);
        };
        meeting.participants.joined.on('participantJoined', joined);
        meeting.participants.joined.on('participantLeft', left);
        meeting.participants.joined.on('audioUpdate', updated);
        meeting.participants.joined.on('videoUpdate', updated);
        return () => {
            meeting.participants.joined.off('participantJoined', joined);
            meeting.participants.joined.off('participantLeft', left);
            meeting.participants.joined.off('audioUpdate', updated);
            meeting.participants.joined.off('videoUpdate', updated);
        };
    }, [meeting, callbacks]);
    useEffect(() => {
        var _a;
        (_a = callbacks === null || callbacks === void 0 ? void 0 : callbacks.onParticipantsChanged) === null || _a === void 0 ? void 0 : _a.call(callbacks, participants);
    }, [callbacks, participants]);
    return participants;
}
