import { deepFreeze } from './Util/util';

/**
 * These colors are mirrored in constants.scss and need to be manually kept in sync if the style guide changes
 */

const InQStyleGuide = deepFreeze({
  blue: {
    dark: '#02253c',
    medium: '#26436e',
    light: '#92a1b7',
    bright: '#077ad3',
  },
  grey: {
    light: '#eaeaea',
    medium: '#939393',
    dark: '#393939',
  },
  green: {
    light: '#EFF9F2',
    mediumLight: '#80B690',
    medium: '#448e1b',
    dark: '#006D21',
  },
  red: {
    light: '#FFE9E9',
    medium: '#E86060',
    dark: '#c20000',
  },
  orange: '#fa7000',
  white: '#ffffff',
  black: '#000000',
});

export default InQStyleGuide;
