var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useEffect } from 'react';
export function useRoomEvents(onRoomJoined, onRoomLeft) {
    const { meeting } = useDyteMeeting();
    useEffect(() => {
        if (!meeting) {
            return;
        }
        const roomJoinedListener = () => __awaiter(this, void 0, void 0, function* () {
            onRoomJoined === null || onRoomJoined === void 0 ? void 0 : onRoomJoined();
        });
        meeting.self.on('roomJoined', roomJoinedListener);
        return () => {
            meeting.self.removeListener('roomJoined', roomJoinedListener);
        };
    }, [meeting, meeting.self, onRoomJoined]);
    useEffect(() => {
        if (!meeting) {
            return;
        }
        const roomLeftListener = () => {
            onRoomLeft === null || onRoomLeft === void 0 ? void 0 : onRoomLeft();
        };
        meeting.self.on('roomLeft', roomLeftListener);
        return () => {
            meeting.self.removeListener('roomLeft', roomLeftListener);
        };
    }, [meeting, onRoomLeft]);
}
