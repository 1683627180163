import { useEffect } from 'react';
import { ACTIVE_SPEAKER_COLOUR, RAISED_HAND_COLOUR } from '../constants';
const highlightedParticipantStyle = (color) => `
  .highlighted-participant {
    border: 3px solid ${color};
    border-radius: 16px;
    box-sizing: border-box;
  }
`;
const highlightedParticipantClass = 'highlighted-participant';
const highlightedParticipantStyleId = 'participant-tile-shadow-style';
function addHighlightedParticipantClass(tile) {
    tile.classList.add(highlightedParticipantClass);
}
function removeHighlightedParticipantClass(tile) {
    tile.classList.remove(highlightedParticipantClass);
}
function appendStyle(tile, color) {
    const prevStyle = tile.querySelector('#' + highlightedParticipantStyleId);
    if (prevStyle) {
        prevStyle.textContent = highlightedParticipantStyle(color);
        return;
    }
    const style = document.createElement('style');
    style.id = highlightedParticipantStyleId;
    style.textContent = highlightedParticipantStyle(color);
    tile.appendChild(style);
}
function removeStyle(tile) {
    const style = tile.querySelector('#' + highlightedParticipantStyleId);
    if (style) {
        style.remove();
    }
}
// Injects style element under participant tiles in Dyte's Grid view component
// Used for setting active speaker and hand raised borders
export function useInjectParticipantTileStyle(participantTiles, speakerIds, handRaisedIds) {
    useEffect(() => {
        if (!participantTiles || (!speakerIds && !handRaisedIds)) {
            return;
        }
        for (const tile of participantTiles) {
            const participant = tile.participant;
            const isSpeaking = speakerIds.includes(participant.id);
            const isHandRaised = handRaisedIds.includes(participant.id);
            if (isSpeaking || isHandRaised) {
                addHighlightedParticipantClass(tile);
                appendStyle(tile, isSpeaking ? ACTIVE_SPEAKER_COLOUR : RAISED_HAND_COLOUR);
            }
            else {
                removeHighlightedParticipantClass(tile);
                removeStyle(tile);
            }
        }
    }, [handRaisedIds, participantTiles, speakerIds]);
}
