import { useTranslation } from 'react-i18next';
import { defaultLanguage, useLanguage } from '@dytesdk/react-ui-kit';
import * as i18n_enJson from '../i18n/en.json';
import * as i18n_frJson from '../i18n/fr.json';
// Dyte's locale object, with default values extended with our translations
export default function useLocale() {
    const { i18n } = useTranslation();
    const selectedLocale = i18n.language === 'fr' ? i18n_frJson : i18n_enJson;
    const languagePack = useLanguage(Object.assign(Object.assign({}, defaultLanguage), selectedLocale));
    return languagePack;
}
