const _ = require('lodash');
const { Observable } = require('rxjs');

const { assertConnection, invokeActionAndWait, invokeApi, invoke } = require('./utils');
const { waitForInvocationReaction } = require('./hive-client-utils-wrapper').utils;
const { getApplicationConfig, getAppState } = require('./support');

const bee = require('hive-bee');

const checkIn = async (patron, initDateTime) =>  {
  const connection = assertConnection();
  return connection.bee.actions.invoke('raam.checkIn', patron, initDateTime)
    .then(waitForInvocationReaction(
      connection.bee,
      r => ({notificationId: r.details.uuid, id: r.details.message.message})
    ));
};

let processPatron = p => _.pick(p, ['id', 'created', 'properties']);

const get = (id) => {
  const { bee } = assertConnection();
  return bee.api.invoke('raam.patronById', id)
    .then(processPatron)
    .catch(() => {});

  // The catch is for the UI to detect there is a problem with the patron and unload it
};

const patronChange = _.curry(async (subscriber, id, notificationId, R) => {
  let rId = _.get(R.details, '0.notificationId');

  if (rId === notificationId) {
    get(id).then((patronI) => {
      subscriber.next(patronI);
    });
  };
});

const observe = ({notificationId, id}) => {
  const connection = assertConnection();

  return new Observable((subscriber) => {
    let {bee} = connection;

    get(id)
      .then((initial) => subscriber.next(initial));

    const callbackIds = bee.reactions.setCallback('invocation:patronChange',
      patronChange(subscriber, id, notificationId));

    return () => {
      bee.reactions.removeCallback(callbackIds);
    };
  });
};

const prepareBlobUpload = (id) => invokeActionAndWait('raam.prepareCardUpload')(id)
  .then((R) => _.get(R, 'details.uuid'))
  .then((blobId) => {
    const baseUrl = bee.urls.shellUrl();

    return `${baseUrl}/blob/upload/${blobId}`;
  });

const updateAppState = (subscriber) => async () => {
  let state = await getAppState();
  subscriber.next(state);
};

const observeAppState = () => {
  const {bee} = assertConnection();

  return new Observable(subscriber => {
    updateAppState(subscriber)();

    const callbackIds = [
      bee.reactions.setCallback('storage:mutate.raam.appState', updateAppState(subscriber)),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

module.exports = {
  getApplicationConfig,
  getCurrentDateTime: invokeActionAndWait('raam.getCurrentDateTime'),
  getRoomToken: (userName, patronId, roomName, pin) =>
    invokeApi('raam.getRoomToken')(userName, patronId, roomName, pin),
  checkIn,
  get,
  observe,
  observeAppState,
  states: {
    toInMeeting: (patronId, careId) => invokeActionAndWait('raam.movePatronInMeeting')(patronId, careId),
    eject: (id, msg) => invokeActionAndWait('raam.leave')(id, msg),
    ackEjected: (id) => invokeActionAndWait('raam.ackEjected')(id),
    ackMeetingRequest: (id) => invoke('raam.ackMeetingRequest')(id),
  },
  assertDenialReport: (type) => invoke('raam.assertDenialReport')(type),
  joinAsGuest: (meetingName, name) => invoke('raam.joinAsGuest')(meetingName, name),
  isOpen: () => invokeActionAndWait('raam.isOpen')(),
  validatePin: (meetingName, pin) => invokeActionAndWait('raam.validatePin')(meetingName, pin),
  setSatisfactionScore: (id, satisfactionScore) =>
    invokeActionAndWait('raam.setSatisfactionScore')(id, satisfactionScore),
  prepareBlobUpload,
  getTodaysMeeting: () => invokeApi('raam.getTodaysMeeting')(),
  setSurvey: (surveyId, satisfactionScore) =>
    invokeApi('raam.setSurvey')(surveyId, satisfactionScore),
  ackSurvey: (id) => invokeApi('raam.ackSurvey')(id),
};
