import { useEffect, useState } from 'react';
import { defaultIconPack } from '@dytesdk/react-ui-kit';
import { applyColor } from '../utils/utils';
import InQStyleGuide from '../constants';
export default function useIconPack() {
    const [customIconPack, setCustomIconPack] = useState(defaultIconPack);
    // Customizing icon colors
    useEffect(() => {
        setCustomIconPack(Object.assign(Object.assign({}, defaultIconPack), { mic_on: applyColor(defaultIconPack.mic_on, InQStyleGuide.lime), share_screen_stop: applyColor(defaultIconPack.share_screen_stop, InQStyleGuide.lime), video_on: applyColor(defaultIconPack.video_on, InQStyleGuide.lime) }));
    }, []);
    return customIconPack;
}
