import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

import { CountdownTimer, SinglePanelWithMessaging } from '@components';

import { usePageVisibility, useTimer } from '@hooks';

import { useExtendedTranslation } from '@services/i18nService';
import {
  ackMeetingRequest,
  getCurrentDateTime,
  startMeeting,
} from '@services/PatronService';

import IPatron from '@typings/IPatron';

import { parseDateToISO } from '@util/date';

import InQStyleGuide from '../../constants';

import '@screens/VisitTimer/VisitTimer.scss';

function VisitTimer({ patron }: { patron: IPatron }) {
  const t = useExtendedTranslation();
  const [loadTime, setLoadTime] = useState(new Date().toISOString());
  useEffect(() => {
    getCurrentDateTime().then((o: { details?: { message?: string } }) =>
      setLoadTime((prev) => _.get(o, ['details', 'message'], prev))
    );
  }, []);
  const expiry = useMemo(
    () => parseDateToISO(_.get(patron, 'properties.meetingRequestExpiry')),
    [patron]
  );
  const hiveTimeOffset = useMemo(
    () => Date.now() - parseDateToISO(loadTime),
    [loadTime]
  );

  const isGroup = useMemo(
    () => !_.isEmpty(_.get(patron, 'properties.groupMeetingName')),
    [patron]
  );

  const [timeLeft, timerActive] = useTimer(expiry, true, hiveTimeOffset);

  const isVisible = usePageVisibility();

  useEffect(() => {
    if (isVisible) {
      ackMeetingRequest(patron?.id);
    }
  }, [patron?.id, isVisible]);

  const handleStartMeetingClick =
    (patronId: string, currentCareId: string) => () => {
      startMeeting(patronId, currentCareId);
      if (!patron.properties.meetingName) {
        window.open(patron?.properties?.meetingUrl);
      }
    };

  return (
    <Box display="flex" flex={1} className="visit-timer">
      <SinglePanelWithMessaging
        patron={patron}
        color="blue"
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              {patron?.properties?.currentCareId ? (
                <Button
                  variant="contained"
                  onClick={handleStartMeetingClick(
                    patron.id,
                    patron.properties.currentCareId
                  )}
                >
                  {t(
                    !isGroup ? 'visittimer.connect' : 'visittimer.groupConnect'
                  )}
                </Button>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        }
      >
        {!isGroup ? (
          <>
            <div className="visit-timer-text">{t('visittimer.ready')}</div>
            <br />
            <br />
            <div className="visit-timer-text">{t('visittimer.hold')}</div>
            <Grid container justifyContent="center">
              <Grid item>
                {timerActive ? (
                  <CountdownTimer seconds={timeLeft} />
                ) : (
                  <Paper className="countdownTimerSpinnerPaper" elevation={4}>
                    <CircularProgress />
                  </Paper>
                )}
              </Grid>
            </Grid>
            <div className="visit-timer-text">
              {t('visittimer.donotconnect')}
            </div>
          </>
        ) : (
          <>
            <Typography
              variant="h3"
              align="center"
              style={{ color: InQStyleGuide.white }}
            >
              {t('visittimer.groupReady')}
            </Typography>
          </>
        )}
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default VisitTimer;
